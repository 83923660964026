<template>
  <div>
    <navBar :title="title"></navBar>
    <ul class="lis">
      <li>
        <span>*修改密码:</span>
        <input v-model="password" type="text" placeholder="请输入新密码">
      </li>
      <li>
        <span>*确认密码:</span>
        <input v-model="checkPassword" type="text" placeholder="请再次输入新密码">
      </li>
    </ul>
    <div class="confirm">
        <van-button @click="confirm" type="info">确认</van-button>
      </div>
  </div>
</template>

<script>
import navBar from '../navBar'
import {Toast} from 'vant';
export default {
  name: '',
   components:{
    navBar
  },
  data () {
    return {
      title: {
        name: '修改密码',
        status: true,
      },
      password:'',
      checkPassword:''
    }
  },
  created () {
  },
  computed: {
  },
  methods: {
    confirm(){
      if(this.password !=='' && this.checkPassword !==''){

        if(this.password == this.checkPassword){
          var obj = {
            id:this.$store.state.userId,
            password:this.password
          }
          this.$request.put(this.$api.passwordChange, obj).then(res =>{
            sessionStorage.removeItem('user-token')
            sessionStorage.clear()
            this.$router.push('/')
            this.$store.state.userId = '';
            this.$store.state.tokenStatus = false;
            window.location.reload()
          })
        }else{
          Toast.fail('两次输入的密码不一致')
        }

      }else{
        Toast.fail('请输入必填项')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.lis{
  >li{
    display: flex;
    height: 36px;
    border-bottom: 1px solid #ccc;
    align-items: center;
    >span{
      width: 20%;
      text-align: right;
    }
    >input{
      width: 70%;
      border: none;
      margin-left: 10px;
    }
  }
}
.confirm {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

/deep/.van-button{
  height: 25px;
}
</style>
